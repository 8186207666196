<template>
  <el-row>
    <el-col>
      <el-card>
        <el-table
        :data="tableData"
        style="width: 100%">
          <el-table-column
            prop="out_trade_no"
            label="订单号"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="套餐名称"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="num"
            label="链接数量"
            width="140"
            align="center"
          >
            <template #default="scope">
              {{ scope.row.num }}条
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="金额"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="购买天数"
            width="100"
            align="center"
          >
            <template #default="scope">
              {{ scope.row.day }}天
            </template>
          </el-table-column>
          <el-table-column
            prop="payment"
            label="支付方式"
            width="100"
            align="center"
          >
            <template #default="scope">
              {{ scope.row.payment === 'wechat' ? '微信' : '支付宝' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            label="支付时间"
            width="140"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            :width="responsively ? 100 : 140"
            align="center"
          >
            <template #default="scope">
              <el-button v-if="!invoice" :size="responsively ? 'mini' : 'medium'" type="text" @click="handleClick(scope.row)">申请开票</el-button>
              <el-button disabled v-else :size="responsively ? 'mini' : 'medium'" type="text">已申请</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card class="page__footer">
        <el-pagination
          background
          :small="responsively"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total"
        />
      </el-card>
    </el-col>
  </el-row>
  <el-dialog
    append-to-body
    destroy-on-close
    title="申请发票"
    :width="480"
    :fullscreen="responsively"
    @close="closeInvoiceDialog"
    v-model="invoiceDialog"
    :close-on-click-modal="false"
  >
    <el-form label-position="left" label-width="100px">
      <el-form-item label="发票类型">
        <el-select v-model="invoiceForms.type" :size="responsively ? 'medium' : 'small'">
          <el-option label="邮寄" value="mail"></el-option>
          <el-option label="线上" value="online"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票抬头">
        <el-select v-model="invoiceForms.risetype" :size="responsively ? 'medium' : 'small'">
          <el-option label="个人" value="personal"></el-option>
          <el-option label="企业" value="company"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票抬头">
        <el-input :size="responsively ? 'medium' : 'small'" v-model="invoiceForms.risename" placeholder="请输入发票抬头"></el-input>
      </el-form-item>
      <el-form-item label="单位税号">
        <el-input :size="responsively ? 'medium' : 'small'" v-model="invoiceForms.tax" placeholder="请输入单位税号"></el-input>
      </el-form-item>
      <el-form-item label="收票人姓名">
        <el-input :size="responsively ? 'medium' : 'small'" v-model="invoiceForms.name" placeholder="请输入收票人姓名"></el-input>
      </el-form-item>
      <el-form-item label="收票人手机号">
        <el-input :size="responsively ? 'medium' : 'small'" v-model="invoiceForms.mobile" placeholder="请输入收票人手机号"></el-input>
      </el-form-item>
      <el-form-item label="收票人地区">
        <elui-china-area-dht @change="onChange"></elui-china-area-dht>
      </el-form-item>
      <el-form-item label="详细地址">
        <el-input :size="responsively ? 'medium' : 'small'" v-model="invoiceForms.address" placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button :size="responsively ? 'medium' : 'small'" type="primary" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { EluiChinaAreaDht } from 'elui-china-area-dht'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat
interface ITableData {
  id: number,
  // eslint-disable-next-line camelcase
  item_id: number,
  name: number,
  intro: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  num: string,
  day: string,
  createtime: string,
  payment: string,
  price: number
}

interface IInvoiceForms {
  [key: string]: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  type: string,
  risetype: string,
  risename: string,
  tax: string,
  name: string,
  mobile: string,
  area: string,
  address: string
}

interface IState {
  page: number,
  pageSize: number,
  total: number,
  tableData: ITableData[] | null,
  invoiceDialog: boolean,
  invoiceForms: IInvoiceForms
}

export default defineComponent({
  name: 'HistoryOrder',
  components: {
    EluiChinaAreaDht
  },
  setup () {
    const { proxy }: any = getCurrentInstance()
    const state = reactive<IState>({
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: null,
      invoiceDialog: false,
      invoiceForms: {
        out_trade_no: '',
        type: 'mail',
        risetype: '',
        risename: '',
        tax: '',
        name: '',
        mobile: '',
        area: '',
        address: ''
      }
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/fee/paylog', {
        page: state.page,
        page_size: state.pageSize
      }).then((res: IResponse) => {
        state.total = res.data.count
        state.tableData = res.data.lists
      })
    }
    getTableList()

    const handleClick = (item: ITableData): void => {
      state.invoiceForms.out_trade_no = item.out_trade_no
      setTimeout(() => {
        state.invoiceDialog = true
      }, 100)
    }

    const closeInvoiceDialog = (): void => {
      setTimeout(() => {
        for (const key in state.invoiceForms) {
          if (key === 'type') {
            state.invoiceForms[key] = 'mail'
          } else {
            state.invoiceForms[key] = ''
          }
        }
      }, 500)
    }

    const onChange = (e: number[]): void => {
      const provinceData = chinaData[e[0]]
      const cityData = chinaData[e[1]]
      const areaData = chinaData[e[2]]
      state.invoiceForms.area = provinceData.label + cityData.label + areaData.label
    }

    const onSubmit = (): void => {
      proxy.$axios.post('/invoice/apply', state.invoiceForms)
        .then((res: IResponse) => {
          if (res.code === 1) {
            ElMessage.success({
              message: '申请成功',
              type: 'success'
            })
            getTableList()
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
          setTimeout(() => {
            state.invoiceDialog = false
          }, 100)
        })
    }

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList()
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList()
    }

    return {
      closeInvoiceDialog,
      ...stateAsRefs,
      onSubmit,
      responsively,
      handleClick,
      onChange,
      getTableList,
      handleSizeChange,
      handleCurrentChange
    }
  }
})
</script>

<style lang="scss" scoped>
  .page {
    @mixin cardBody {
      :deep(.el-card__body) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px;
      }
    }
    &__footer {
      margin: 12px 0 0;
      @include cardBody;
    }
  }
</style>
