
import { IResponse } from '@/common/http'
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { EluiChinaAreaDht } from 'elui-china-area-dht'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat
interface ITableData {
  id: number,
  // eslint-disable-next-line camelcase
  item_id: number,
  name: number,
  intro: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  num: string,
  day: string,
  createtime: string,
  payment: string,
  price: number
}

interface IInvoiceForms {
  [key: string]: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  type: string,
  risetype: string,
  risename: string,
  tax: string,
  name: string,
  mobile: string,
  area: string,
  address: string
}

interface IState {
  page: number,
  pageSize: number,
  total: number,
  tableData: ITableData[] | null,
  invoiceDialog: boolean,
  invoiceForms: IInvoiceForms
}

export default defineComponent({
  name: 'HistoryOrder',
  components: {
    EluiChinaAreaDht
  },
  setup () {
    const { proxy }: any = getCurrentInstance()
    const state = reactive<IState>({
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: null,
      invoiceDialog: false,
      invoiceForms: {
        out_trade_no: '',
        type: 'mail',
        risetype: '',
        risename: '',
        tax: '',
        name: '',
        mobile: '',
        area: '',
        address: ''
      }
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/fee/paylog', {
        page: state.page,
        page_size: state.pageSize
      }).then((res: IResponse) => {
        state.total = res.data.count
        state.tableData = res.data.lists
      })
    }
    getTableList()

    const handleClick = (item: ITableData): void => {
      state.invoiceForms.out_trade_no = item.out_trade_no
      setTimeout(() => {
        state.invoiceDialog = true
      }, 100)
    }

    const closeInvoiceDialog = (): void => {
      setTimeout(() => {
        for (const key in state.invoiceForms) {
          if (key === 'type') {
            state.invoiceForms[key] = 'mail'
          } else {
            state.invoiceForms[key] = ''
          }
        }
      }, 500)
    }

    const onChange = (e: number[]): void => {
      const provinceData = chinaData[e[0]]
      const cityData = chinaData[e[1]]
      const areaData = chinaData[e[2]]
      state.invoiceForms.area = provinceData.label + cityData.label + areaData.label
    }

    const onSubmit = (): void => {
      proxy.$axios.post('/invoice/apply', state.invoiceForms)
        .then((res: IResponse) => {
          if (res.code === 1) {
            ElMessage.success({
              message: '申请成功',
              type: 'success'
            })
            getTableList()
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
          setTimeout(() => {
            state.invoiceDialog = false
          }, 100)
        })
    }

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList()
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList()
    }

    return {
      closeInvoiceDialog,
      ...stateAsRefs,
      onSubmit,
      responsively,
      handleClick,
      onChange,
      getTableList,
      handleSizeChange,
      handleCurrentChange
    }
  }
})
